<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs5>
              <v-text-field
                v-model="dealer"
                append-icon="search"
                label="Enter dealer"
                clearable
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="reactivate"
        class="elevation-1"
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.dealerId }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-center">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-right">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-right">
            {{ props.item.active }}
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Revoke Basic Dealer
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Revoke Basic Dealer</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === "info" ? "Cancel" : "Close" }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              color="blue darken-1"
              flat
              @click="executeRevokeBasic"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Revoke Basic Dealer'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    title: pageTitle,
    reactivate: [],
    dealer: null,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    loading: false,
    breadcrumbs: [
      {
        text: 'Technical Kits',
        disabled: false,
        to: '/technical/technical_kits',
      },
      {
        text: pageTitle,
        disabled: true,
      },
    ],
    headers: [
      {
        text: 'Dealer Id',
        align: 'left',
        sortable: true,
        value: 'dealer',
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Msisdn',
        align: 'center',
        sortable: true,
        value: 'msisdn',
      },
      {
        text: 'Plan',
        align: 'right',
        sortable: true,
        value: 'plan',
      },
      {
        text: 'Dreg Status',
        align: 'right',
        sortable: true,
        value: 'active',
      },
    ],
    editedIndex: -1,
    reactivateDealer: {
      dealer: null,
    },
    defaultDealer: {
      dealer: null,
    },
  }),
  watch: {
    dealer: function (val) {
      this.getRevokeBasicDealer(val)
    },
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage =
          '<div>Click <strong>EXECUTE</strong> only when you are sure that all dealer info are correct with the info given by DMT.</div>'
      }
    },
  },
  methods: {
    getRevokeBasicDealer () {
      const params = createGetParams({
        dealerId: this.dealer,
      })
      this.loading = true
      this.$rest
        .get('getRevokeBasicDealer.php', params)
        .then((response) => {
          this.reactivate = response.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    async executeRevokeBasic () {
      const params = createPostParams({
        action: 'revokeBasic',
        payload: {
          dealer: this.dealer,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage =
            'You have successfully revoke the dealer account'
          this.dealer = []
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },
    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
